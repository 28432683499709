import MessageForm from '@/src/components/Chatbot/MessageForm';
import MessagesList from '@/src/components/Chatbot/MessageList';
import { MessagesProvider } from '@/src/hooks/chatbot';
import * as AssistantHeader from '@/src/modules/assistant/components/AssistantHeader';
import { AssistantQuickActions } from '@/src/modules/assistant/components/AssistantQuickActions';
import { useSidebarScrollbarFadeColor } from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/Sidebar';
import { Flex } from '@/src/modules/ui/components/Flex';
import { P } from '@/src/modules/ui/components/Typography';
import { ForcedTheme } from '@/src/modules/ui/theme/ForcedTheme';
import { Fdoc } from '@/src/types/api';
import { AnimatePresence } from 'framer-motion';

const ExpandedFdocSidebarAssistant: React.FC<{
  isFullscreen: boolean;
  resource: Fdoc;
  canAutoFocus?: boolean;
}> = ({ isFullscreen, resource, canAutoFocus }) => {
  const fadeColor = useSidebarScrollbarFadeColor();

  return (
    <MessagesProvider chatAssistantOpen={true} expandedFdocId={resource.id}>
      <ForcedTheme colorScheme="dark" asChild disabled={isFullscreen}>
        <Flex
          direction="column"
          style={{ height: '100%', position: 'relative', overflow: 'hidden' }}
        >
          <AssistantHeader.Root>
            <AssistantHeader.Left>
              <P size="xs">Asking about:</P>
              <AssistantHeader.ContextSelector />
            </AssistantHeader.Left>
            <AssistantHeader.ResetButton />
          </AssistantHeader.Root>
          <MessagesList fadeColor={fadeColor} />
          <AssistantQuickActions />
        </Flex>
      </ForcedTheme>
      <AnimatePresence initial={false}>
        <MessageForm autoFocus={canAutoFocus} />
      </AnimatePresence>
    </MessagesProvider>
  );
};

export default ExpandedFdocSidebarAssistant;
