import { useAuthUser } from '@/src/hooks/auth';
import { useResponsive } from '@/src/hooks/responsive';
import { useRunOnce } from '@/src/hooks/useRunOnce';
import CloseIcon from '@/src/icons/CloseIcon';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { Comment } from '@/src/modules/comments/comments.types';
import { useMutationCreateComment } from '@/src/modules/comments/mutations/useMutationCreateComment';
import { useMutationUpdateComment } from '@/src/modules/comments/mutations/useMutationUpdateComment';
import * as Conversation from '@/src/modules/ui/components/Conversation';
import { framerAnimationSlideUp } from '@/src/modules/ui/constants/framerAnimations';
import { ForcedTheme } from '@/src/modules/ui/theme/ForcedTheme';
import { OptimisticDraft } from '@/src/types/draftable';
import DashboardButton from '@/src/ui/DashboardButton/DashboardButton';
import { ArrowUpIcon, CheckIcon } from '@radix-ui/react-icons';
import { AnimatePresence } from 'framer-motion';
import Link from 'next/link';
import router from 'next/router';
import React from 'react';
import styled from 'styled-components';
import styles from './ExpandedFdoc.module.scss';

export const ExpandedFdocSidebarAddComment: React.FC<{
  resourceId: string;
  isFullscreen: boolean;
  editingComment: OptimisticDraft<Comment> | null;
  onCancelEditingComment: VoidFunction;
  animateIn?: boolean;
  canAutoFocus?: boolean;
}> = ({
  resourceId,
  editingComment,
  onCancelEditingComment,
  animateIn = true,
  canAutoFocus = true,
}) => {
  const { track } = useAnalytics();

  const user = useAuthUser();
  const { isDesktopView, isMobileView } = useResponsive();
  const [textAreaRef, setTextAreaRef] = React.useState<HTMLTextAreaElement | null>(null);

  const [content, setContent] = React.useState<string>('');

  const wasEditingComment = React.useRef(Boolean(editingComment));

  /**
   * if new comment to edit, we update the content
   */
  React.useEffect(() => {
    if (editingComment) {
      setContent(editingComment.content);
      setTimeout(() => {
        textAreaRef?.focus();
      }, 200);
    } else if (wasEditingComment.current) {
      setContent('');
      textAreaRef?.blur();
    }
    wasEditingComment.current = Boolean(editingComment);
  }, [editingComment, textAreaRef]);

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
  };

  const mutationCreateComment = useMutationCreateComment();
  const mutationUpdateComment = useMutationUpdateComment();

  const handleSubmit = () => {
    const trimmed = content.trim();
    if (trimmed && user) {
      setContent('');

      if (editingComment) {
        mutationUpdateComment.mutate(
          {
            comment: editingComment,
            newContent: trimmed,
          },
          {
            onSuccess: () => {
              onCancelEditingComment();
            },
            onError: () => {
              setContent(trimmed);
            },
          },
        );
      } else {
        mutationCreateComment.mutate(
          { resourceId, content: trimmed },
          {
            onSuccess: () => {
              track(AnalyticsEvents.CreatedComment, {
                action: 'expanded_fdoc',
              });
            },
            onError: () => {
              setContent(trimmed);
            },
          },
        );
      }
    }
  };

  /**
   * handle enter key to submit comment
   */
  const handleTextAreaKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSubmit();
      e.preventDefault();
    }
  };

  /**
   * This might seem unnecessary but on the expanded resource for some reason when selecting a tab
   * the tab steals focus a few MS after the tab is selected. This causes the input to blur right after
   * being auto focused.
   */
  useRunOnce(() => {
    if (!canAutoFocus) return;

    window.requestAnimationFrame(() => {
      textAreaRef?.focus();
    });
  }, Boolean(textAreaRef));

  return user ? (
    <AnimatePresence initial={animateIn && isMobileView}>
      <ForcedTheme colorScheme="light" asChild>
        <Conversation.Form {...framerAnimationSlideUp}>
          {editingComment && (
            <div
              className={styles.item_preview__sidebar__comments__edit_box}
              onClick={onCancelEditingComment}
            >
              <EditCommentCloseWrapper>
                <CloseIcon />
              </EditCommentCloseWrapper>
              <span>Editing comment</span>
            </div>
          )}
          <Conversation.FormField>
            <Conversation.FormTextArea
              placeholder="Add a comment"
              value={content}
              onChange={handleTextAreaChange}
              onKeyDown={handleTextAreaKeyDown}
              ref={setTextAreaRef}
              autoFocus={canAutoFocus}
              rows={1}
              data-testid="expanded-fdoc-comment-input"
            />

            <Conversation.FormButton
              aria-label="Add a comment"
              title="Add a comment"
              disabled={!content || content.trim().length === 0}
              onClick={handleSubmit}
              data-testid="expanded-fdoc-comment-submit"
            >
              {editingComment ? <CheckIcon style={{ strokeWidth: 2 }} /> : <ArrowUpIcon />}
            </Conversation.FormButton>
          </Conversation.FormField>
        </Conversation.Form>
      </ForcedTheme>
    </AnimatePresence>
  ) : (
    <div
      className={styles.item_preview__sidebar__comments__input_wrapper}
      style={{
        gap: 15,
        background: '#2E2E2E',
        borderBottomLeftRadius: isDesktopView ? 20 : 0,
        borderBottomRightRadius: isDesktopView ? 20 : 0,
        bottom: 0,
      }}
    >
      <p>You need to login to respond.</p>
      <DashboardButton color="primary" size="medium" className={styles.signup_button}>
        <Link href={`/beta-sign-up-exclusive-link/${router.query.secret}`} />
        Sign up / Login
      </DashboardButton>
    </div>
  );
};

const EditCommentCloseWrapper = styled.div`
  border-radius: 50%;
  height: 1.0625rem;
  width: 1.0625rem;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  svg {
    height: 0.6125rem;
    width: 0.6125rem;
  }
`;
